import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`A lot of random topics`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com"
      }}>{`work stuff`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com"
      }}>{`experiences`}</a>{` – `}<a parentName="p" {...{
        "href": "http://pixels.fi"
      }}>{`more experiences`}</a></p>
    <h3>{`Our background`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com"
      }}>{`booncon`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/South_Tyrol"
      }}>{`South Tirol`}</a></p>
    <h3>{`Snucking out in Italy`}</h3>
    <p>{`work tools: `}<a parentName="p" {...{
        "href": "http://trello.com"
      }}>{`Trello`}</a>{`, `}<a parentName="p" {...{
        "href": "http://basecamp.com"
      }}>{`Basecamp`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.labruschettamerano.com"
      }}>{`pizza place of choice`}</a></p>
    <h3>{`Tobi gets trolled by a Brit`}</h3>
    <p><a parentName="p" {...{
        "href": "http://facebook.com/"
      }}>{`Facebook`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Troll_(Internet)"
      }}>{`Trolling`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/European_Union"
      }}>{`European Union`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Euro"
      }}>{`Euro`}</a></p>
    <h3>{`Luki doesn't have a life`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/sftsk#contributions-calendar"
      }}>{`crazy hours`}</a></p>
    <h3>{`This weeks podcast recommendation`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.stuffyoushouldknow.com/podcasts/"
      }}>{`Stuff You Should Know`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.stuffyoushouldknow.com/podcasts/how-ice-cream-works/"
      }}>{`How Ice Cream Works`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.stuffyoushouldknow.com/podcasts/how-feng-shui-works/"
      }}>{`How Feng Shui Works`}</a>{` – `}<a parentName="p" {...{
        "href": "http://fengshui.about.com/od/fengshuiforbedroom/qt/mirror_bed.htm"
      }}>{`bedroom mirrors`}</a></p>
    <h3>{`Plans for the future`}</h3>
    <p>{`Helsinki Startup Scene: `}<a parentName="p" {...{
        "href": "http://aaltoes.com"
      }}>{`Aaltoes`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.slush.org"
      }}>{`Slush`}</a>{`, `}<a parentName="p" {...{
        "href": "http://startupsauna.com"
      }}>{`Startup Sauna`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.arcticstartup.com"
      }}>{`ArcticStartup`}</a>{` – `}<a parentName="p" {...{
        "href": "http://boonconic.com/friendolleagues/"
      }}>{`Work methods`}</a></p>
    <h3>{`Outro`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      